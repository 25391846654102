<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="fixed z-20 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition-group enter-active-class="transform ease-out duration-300 transition"
                              enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                              enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                              leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                              leave-to-class="opacity-0">
                <component :is="notification.component" v-for="(notification, index) in notifications" :key="notification.id"
                              :index="index" :notification="notification" @remove="removeNotification"/>
            </transition-group>
        </div>
    </div>
</template>

<script>
import Notification from "./Notification";
import MediaNotification from "./MediaNotification";
import {NotificationObj} from "../../../modules/notification";

export default {
	name: "UiNotifications",
	components: {Notification, MediaNotification},
	data: () => ({
		notifications: [],
	}),
	methods: {
		pushNotification(type, title, message, mediaUrl = "", actions = []) {
			this.notifications.unshift(new NotificationObj({
				id: Math.random().toString(36).substr(2, 9),
				type,
				title,
				message,
				mediaUrl,
				actions,
			}));
		},
		mediaNotification(title, message = "", mediaUrl = "", actions = []) {
			this.pushNotification("media", title, message, mediaUrl, actions);
		},
		successNotification(title, message = "") {
			this.pushNotification("success", title, message);
		},
		errorNotification(params) {
			if (params.length <= 2) {
				const [title, message] = params;
				this.pushNotification("error", title, message);
			} else if (params[0] instanceof Error) {
				const error = params[0];
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
					this.pushNotification("error", "Error", error.response.data.message);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
					this.pushNotification("error", "Error");
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Error", error.message);
					this.pushNotification("error", "Error", error.message);
				}
			}
		},
		warningNotification(title, message = "") {
			this.pushNotification("warning", title, message);
		},
		infoNotification(title, message = "") {
			this.pushNotification("info", title, message);
		},
		removeNotification(index) {
			this.notifications.splice(index, 1);
		}
	},
	mounted() {
		this.$emitter.on("successNotification", this.successNotification);
		this.$emitter.on("errorNotification", this.errorNotification);
		this.$emitter.on("warningNotification", this.warningNotification);
		this.$emitter.on("infoNotification", this.infoNotification);
	},
	beforeUnmount() {
		this.$emitter.off("successNotification", this.successNotification);
		this.$emitter.off("errorNotification", this.errorNotification);
		this.$emitter.off("warningNotification", this.warningNotification);
		this.$emitter.off("infoNotification", this.infoNotification);
	}
};
</script>