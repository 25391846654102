<template>
    <div
        class="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        @click="remove"
        @mouseout="startTimeout"
        @mouseover="removeTimeout">
        <div class="p-4">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <component :is="notification.icon"  :class="`text-${notification.color}`" aria-hidden="true" class="h-6 w-6" />
                </div>
                <div class="ml-3 w-0 flex-1">
                    <p v-if="notification.title" class="text-sm font-medium text-gray-900 dark:text-gray-100" v-text="notification.title"/>
                    <p v-if="notification.message" class="mt-1 text-sm text-gray-500 dark:text-gray-300" v-text="notification.message"/>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                    <button
                        class="bg-white dark:bg-black rounded-md inline-flex text-gray-400 dark:text-gray-600 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click="remove">
                        <span class="sr-only">Close</span>
                        <XMarkIcon aria-hidden="true" class="h-5 w-5"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {XMarkIcon} from "@heroicons/vue/24/solid";
import notification from "src/mixins/notification";

export default {
	components: {
		CheckCircleIcon,
		ExclamationTriangleIcon,
		ExclamationCircleIcon,
		InformationCircleIcon,
		XMarkIcon,
	},
	name: "UiNotification",
	mixins: [notification],
	methods: {
		remove() {
			this.$emit("remove", this.index);
			this.removeTimeout();
		},
		startTimeout() {
			this.timeout = setTimeout(this.remove, this.delay);
		},
		removeTimeout() {
			clearTimeout(this.timeout);
		}
	},
	mounted() {
		this.startTimeout();
	}
};
</script>
