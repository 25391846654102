<template>
  <img :src="src" :alt="name" />
</template>

<script>
export default {
	name: "LogoImg",
	props: {
		dark: Boolean,
	},
	computed: {
		src() {
			return this.$whitelabel.logo(this.dark);
		},
		name() {
			return this.$whitelabel.appName;
		}
	}
};
</script>