import {Locale} from "../modules/locale";

export class Model {
	constructor(data = null) {
		this.isLoaded = false;
		this.parsedLocales = false;
		if (data) {
			for (let key in data) {
				this[key] = data[key];
			}
			this._parseLocales(data);
			this.isLoaded = true;
		}
	}

	get editableProps() {
		return [];
	}

	get defaultLocale() {
		return this.locales[0].key;
	}

	get defaultLocaleName() {
		return this.locales[0].label;
	}

	static fromArray(data) {
		return (data || []).map(item => new this(item));
	}

	_parseLocales(data) {
		if (!this.parsedLocales && data?.locales && Array.isArray(data.locales)) {
			this.locales = data.locales.map(locale => Locale.find(locale));
		}
		if (data?.translated_locales && Array.isArray(data.translated_locales)) {
			this.translated_locales = data.translated_locales.map(locale => Locale.find(locale));
		}
		this.parsedLocales = true;
	}

	date(key, format = "LL") {
		return window.dayjs(this[key]).format(format);
	}

	update(data = {}) {
		const props = /* this.editableProps.length ? this.editableProps : */ Object.keys(data);
		for (const key of props) {
			if (!Object.prototype.hasOwnProperty.call(data, key)) {
				continue;
			}
			this[key] = data[key];
		}
		this.parsedLocales = false;
		this._parseLocales(data);
		this.isLoaded = true;
	}

	pack() {
		return this.editableProps
			.filter(prop => prop !== "isLoaded")
			.reduce((c, i) => {
				c[i] = this[i];
				return c;
			}, {});
	}

	toString() {
		return JSON.stringify(this);
	}
}
