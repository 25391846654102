<template>
  <Admin v-if="isLoggedIn && isAdminRoute">
    <router-view v-slot="{Component, route}">
      <transition :name="route.meta.transitionName || 'fade'" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  </Admin>
  <Front v-if="isLoggedIn && isStudentRoute" :user="user">
    <router-view v-slot="{Component, route}">
      <transition :name="route.meta.transitionName || 'fade'" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  </Front>

  <router-view v-if="isGuestRoute"/>

  <div class="relative z-200">
    <notifications ref="notification"/>
    <confirm ref="confirm"/>
  </div>
</template>

<script>
import Auth from "src/modules/auth";
import Notifications from "./components/global/ui/Notifications";
import Confirm from "./components/global/ui/Confirm";
import handle_errors from "./mixins/handle_errors";
import Admin from "./layouts/Admin";
import Front from "./layouts/Front";

export default {
	name: "App",
	components: {
		Front,
		Admin,
		Confirm,
		Notifications,
	},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				if (to.meta.title) {
					document.title = `${this.$t(to.meta.title)} - ${this.$whitelabel.appName}`;
				}
			}
		},
	},
	data: () => ({
		slideoverComponent: null,
		slideoverProps: null,
		locales: [],
		messages: [],
	}),
	created() {
		if (this.isLoggedIn && !this.isAdmin) {
			this.listen();
		}

		const params = new URLSearchParams(window.location.search);
		if (params.has("lang")) {
			this.$language.courseAndPlatform = params.get("lang");
			this.$router.refresh();
		}
	},
	mixins: [handle_errors],
	computed: {
		isAdminRoute() {
			return this.$router.isAdminRoute();
		},
		isStudentRoute() {
			return this.$router.isStudentRoute();
		},
		isGuestRoute() {
			return this.$router.isGuestRoute();
		},
		isAuthPage() {
			return this.$router.isAuthRoute();
		},
		isLoggedIn() {
			return Auth.instance.isLoggedIn;
		},
		user() {
			return Auth.instance.user;
		},
		isTeacher() {
			return this.user.isTeacher;
		},
		isAdmin() {
			return this.user.isAdmin;
		},
		isStudent() {
			return this.user.isStudent;
		}
	},
	methods: {
		successNotification(title, message) {
			this.$refs.notification.successNotification(title, message);
		},
		errorNotification(...params) {
			this.$refs.notification.errorNotification(params);
		},
		warningNotification(title, message) {
			this.$refs.notification.warningNotification(title, message);
		},
		infoNotification(title, message) {
			this.$refs.notification.infoNotification(title, message);
		},
		mediaNotification(title, message, mediaUrl, actions) {
			this.$refs.notification.mediaNotification(title, message, mediaUrl, actions);
		},
		confirm(title, message, ok, cancel) {
			return this.$refs.confirm.show(title, message, ok, cancel);
		},
		popup() {
			return this.$refs.popup.show();
		},
		logout(student = false) {
			const route = this.isAdminRoute ? "admin.login" : "login";
			this.$remote.request(student ? "student.logout" : "logout")
				.then(({message}) => {
					this.successNotification(message);
					Auth.instance.logout();
					this.$router.push({name: route});
				})
				.catch(error => this.handleErrors(error));
		},
		badgeCreatedHandler(data) {
			const mediaUrl = `/img/badges/${data.badge_type}.svg`;
			this.mediaNotification(data.title, data.message, mediaUrl, [{
				"label": this.$t("Show"),
				"name": "front.students.show",
				"params": {"page": "badges"},
			}]);
		},
		listen() {
			console.log(`listening to notifications.${this.user.id}`);
			this.$echo.private(`notifications.${this.user.id}`)
				.listen(".badge.created", this.badgeCreatedHandler);
		},
	},
};
</script>

<style lang="scss">

@import '~microtip/microtip';
@import "~nprogress/nprogress.css";

.focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #000);
  border-color: #2563eb;
}

[drag-over] {
  opacity: .4;
}

.absolute-center {
  left: 50%;
  transform: translateX(-50%);
}

.cursor-hand {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

#editorjs {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1.00em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  h1 {
    font-family: 'Trocchi', serif;
    font-size: 45px;
    font-weight: normal;
    line-height: 48px;
    margin: 0;
  }


  h2 {
    font-family: 'Trocchi', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    margin: 0
  }


  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 32px;
    margin: 0 0 24px;
  }

}

/* Transitions */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>