// eslint-disable-next-line no-undef
import Whitelabel from "./modules/whitelabel";

// eslint-disable-next-line no-undef
require("./bootstrap");

import "./libs/editorjs-columns/editorjs-columns.css";
import "./assets/style.css";

import {createApp} from "vue";
import Echo from "laravel-echo";
import {setupRouter} from "./router/router";
import Remote from "./modules/remote";
import Language from "./modules/language";
import initProgress from "./modules/progressbar";

import {setupI18n} from "./i18n";
import App from "./App.vue";
import difficulties from "./data/difficulties.json";
import locales from "./data/locales.json";

import Emitter from "tiny-emitter";

(async () => {
	const language = new Language();
	const i18n = await setupI18n(language.platformLanguage);
	language.updateI18n(i18n);
	window.dayjs.locale(language.platformLanguage);

	const remote = new Remote();
	const router = setupRouter();
	const app = createApp(App);
	
	const whitelabel = new Whitelabel(remote);
	await whitelabel.init();
	whitelabel.setFavicon();

	app.use(router);
	app.use(i18n);

	app.config.globalProperties.$echo = new Echo({
		broadcaster: "pusher",
		key: "035ad478341f0dcc6247",
		cluster: "eu",
		forceTLS: true,
		authorizer: channel => {
			return {
				authorize: (socketId, callback) => {
					remote.sendData("broadcasting.auth", {
						socket_id: socketId,
						channel_name: channel.name
					})
						.then(data => callback(false, data))
						.catch(error => callback(true, error));
				}
			};
		},
	});

	app.config.globalProperties.$remote = remote;
	app.config.globalProperties.$language = language;
	app.config.globalProperties.$whitelabel = whitelabel;
	app.config.globalProperties.$emitter = new Emitter();

	window.global = {};
	window.global.locales = locales;
	window.global.difficulties = difficulties;

	initProgress(router);

	app.mount("#app");
})().catch(e => console.error(e));

