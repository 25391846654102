import {NotificationObj} from "../modules/notification";

export default {
	data: () => ({
		timeout: null,
	}),
	props: {
		notification: NotificationObj,
		index: Number,
		delay: {
			type: Number,
			default: 5 * 1000
		}
	},
	methods: {
		remove() {
			this.$emit("remove", this.index);
			this.removeTimeout();
		},
		startTimeout() {
			this.timeout = setTimeout(this.remove, this.delay);
		},
		removeTimeout() {
			clearTimeout(this.timeout);
		}
	},
	mounted() {
		this.startTimeout();
	}
};