<template>
  <TransitionRoot :show="open" as="template">
    <Dialog :open="open" as="div" class="fixed z-200 inset-0 overflow-y-auto" static @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                         enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100"
                         leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500/80 dark:bg-gray-900/80 transition-opacity backdrop-blur-md"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
              class="inline-block align-bottom bg-white dark:bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div
                  class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon aria-hidden="true" class="h-6 w-6 text-red-600"/>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                             v-text="title"/>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-300" v-text="message"/>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  type="button"
                  @click="_confirm" v-text="$t(okButton)"/>
              <button ref="cancelButtonRef"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-700 shadow-sm px-4 py-2 bg-white dark:bg-black text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      type="button" @click="_cancel" v-text="$t(cancelButton)"/>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ExclamationTriangleIcon} from "@heroicons/vue/24/outline";
import ConfirmCanceled from "@/errors/ConfirmCanceled";

export default {
	name: "UiConfirm",
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ExclamationTriangleIcon,
	},
	data: () => ({
		open: false,
		title: "",
		message: "",
		okButton: "",
		cancelButton: "",
		resolvePromise: undefined,
		rejectPromise: undefined,
	}),
	methods: {
		show(title, message, ok = "OK", cancel = "Cancel") {
			this.title = title;
			this.message = message;
			this.okButton = ok;
			if (cancel) {
				this.cancelButton = cancel;
			}
			// Once we set our config, we tell the popup modal to open
			this.open = true;
			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},

		_confirm() {
			this.open = false;
			this.resolvePromise();
		},

		_cancel() {
			this.open = false;
			this.rejectPromise(new ConfirmCanceled());
		},

	}
};
</script>
