import {Model} from "./Model";

const TEACHER_ROLE = "TEACHER";
const ADMIN_ROLE = "ADMIN";
const STUDENT_ROLE = "STUDENT";
const GUEST_ROLE = "GUEST";

export class User extends Model {
	get isTeacher() {
		return this.role.toUpperCase() === TEACHER_ROLE;
	}

	get isAdmin() {
		return this.role.toUpperCase() === ADMIN_ROLE;
	}

	get isStudent() {
		return this.role.toUpperCase() === STUDENT_ROLE;
	}

	get isGuest() {
		return this.role.toUpperCase() === GUEST_ROLE;
	}

	get full_name() {
		return this.name;
	}

	get fullName() {
		return this.first_name + " " + this.last_name;
	}

	get hasCommunity() {
		if (! Object.hasOwn(this.settings, "has_community")) {
			return true;
		}

		return this.settings.has_community;
	}

	get hasLeaderboard() {
		if (! Object.hasOwn(this.settings, "has_leaderboard")) {
			return true;
		}

		return this.settings.has_leaderboard;
	}

	get hasCertificateCeo() {
		if (! Object.hasOwn(this.settings, "has_certificate_ceo")) {
			return true;
		}

		return this.settings.has_certificate_ceo;
	}

	static guest() {
		return new User({
			"first_name": "Guest",
			"last_name": "Guest",
			"email": "",
			"role": GUEST_ROLE
		});
	}
}