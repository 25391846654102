<template>
  <div class="flex bg-white dark:bg-black overflow-hidden">
    <template v-if="!isAuthPage">
      <!-- Narrow sidebar -->
      <div id="narrow-menu" class="hidden h-screen sticky top-0 w-28 bg-indigo-700 overflow-y-auto md:block transition-all" :style="menuStyle">
        <div class="w-full h-full py-6 flex flex-col items-center">
          <router-link to="/" class="flex-shrink-0 flex items-center">
            <logo-img class="h-8 w-auto" dark/>
          </router-link>
          <div class="flex-1 mt-6 w-full px-2 space-y-1">
            <NavLink v-for="item in navigation" :key="item.name" :item="item">
              <span class="material-icons-round md-24">{{ item.icon }}</span>
              <span class="mt-0.5 text-center">{{ $t(item.name) }}</span>
              <span
                  class="absolute top-4 right-5 flex items-center justify-center text-xxs bg-red-500 text-white w-4 h-4 rounded-full shadow shadow-md"
                  v-if="item.badge" v-text="item.badge"/>
            </NavLink>

<!--            <router-link v-for="item in navigation" :key="item.name" :to="{name: item.route, params: item.params}"-->
<!--                         v-slot="{ href, isActive, navigate }" custom>-->
<!--              <a @click="navigate" :href="href"-->
<!--                 :class="[isActive ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group relative w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']"-->
<!--                 :aria-current="isActive ? 'page' : undefined">-->
<!--                <span class="material-icons-round md-24">{{ item.icon }}</span>-->
<!--                <span class="mt-0.5 text-center">{{ $t(item.name) }}</span>-->
<!--                <span-->
<!--                    class="absolute top-4 right-5 flex items-center justify-center text-xxs bg-red-500 text-white w-4 h-4 rounded-full shadow shadow-md"-->
<!--                    v-if="item.badge" v-text="item.badge"/>-->
<!--              </a>-->
<!--            </router-link>-->
          </div>
          <div class="flex-grow"></div>
          <div class="flex-shrink-0 p-4 pb-0">
            <a @click.prevent="showProfileNavigation = !showProfileNavigation" href="#"
               class="user flex-shrink-0 group block text-center">
              <div class="avatar-wrapper inline-block h-12 w-12"
                   :class="{'avatar-wrapper--open': showProfileNavigation}">
                <UserAvatar :user="user"/>
              </div>
              <p class="text-sm leading-5 font-medium text-white group-hover:text-gray-100 mt-2">
                {{ user.fullName }}
              </p>
            </a>
          </div>
          <Collapsible>
            <div v-if="showProfileNavigation" class="mt-6 w-full px-2 space-y-1">
              <template v-for="item in profileNavigation">
                <router-link v-if="item.route" :key="item.name" :to="{name: item.route, params: item.params}"
                             v-slot="{ href, isActive, navigate }" custom>
                  <a @click="navigate" @mouseup="showProfileNavigation = false" :href="href"
                     :class="[isActive ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']"
                     :aria-current="isActive ? 'page' : undefined">
                    <component :is="item.icon"
                               :class="[isActive ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'h-6 w-6']"
                               aria-hidden="true"/>
                    <span class="mt-2">{{ item.name }}</span>
                  </a>
                </router-link>
                <a v-else :key="item.name" @click="item.handler" @mouseup="showProfileNavigation = false" href="#"
                   class="text-center text-indigo-100 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium">
                  <component :is="item.icon" class="text-indigo-300 group-hover:text-white h-6 w-6" aria-hidden="true"/>
                  <span class="mt-2">{{ item.name }}</span>
                </a>
              </template>
            </div>
          </Collapsible>
        </div>
      </div>

      <!-- Mobile menu -->
      <TransitionRoot as="template" :show="mobileMenuOpen">
        <Dialog as="div" class="md:hidden" @close="mobileMenuOpen = false">
          <div class="fixed inset-0 z-1000 flex">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
                             enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
                             leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay
                  class="fixed inset-0 bg-gray-500/80 dark:bg-gray-700/80 transition-opacity backdrop-blur-md"/>
            </TransitionChild>
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                             enter-from="-translate-x-full" enter-to="translate-x-0"
                             leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                             leave-to="-translate-x-full">
              <div class="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                 enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100"
                                 leave-to="opacity-0">
                  <div class="absolute top-1 right-0 -mr-14 p-1">
                    <button type="button"
                            class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                            @click="mobileMenuOpen = false">
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                      <span class="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex-shrink-0 px-4 flex items-center">
                  <logo-img class="h-8 w-auto" dark/>
                </div>
                <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav class="h-full flex flex-col">
                    <div class="space-y-1">
                      <router-link v-for="item in navigation" :key="item.name"
                                   :to="{name: item.route, params: item.params}" v-slot="{ href, isActive, navigate }"
                                   custom>
                        <a @click="navigate" @mouseup="mobileMenuOpen = false" :href="href"
                           :class="[isActive ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']"
                           :aria-current="isActive ? 'page' : undefined">
                          <component :is="item.icon"
                                     :class="[isActive ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'mr-3 h-6 w-6']"
                                     aria-hidden="true"/>
                          <span>{{ item.name }}</span>
                        </a>
                      </router-link>
                    </div>
                    <div class="flex-grow"></div>
                    <div class="text-indigo-100 group py-2 px-3 rounded-md flex items-center text-sm font-medium">
                      <div class="inline-block h-12 w-12 mr-3 rounded-full">
                        <UserAvatar :user="user"/>
                      </div>
                      <div>
                        <span class="text-lg">{{ user.fullName }}</span>
                        <a @click.prevent="logout" href="#" class="block text-sm">{{ $t("Log out") }}</a>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 w-14" aria-hidden="true">
              <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </template>

    <div class="h-screen flex-1 min-w-0 flex flex-col overflow-hidden">
      <!-- Mobile top navigation -->
      <div v-if="!isAuthPage" class="md:hidden">
        <div class="bg-indigo-600 py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
          <div>
            <logo-img class="h-8 w-auto" dark/>
          </div>
          <div>
            <button type="button"
                    class="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-indigo-600 rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="mobileMenuOpen = true">
              <span class="sr-only">Open sidebar</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
            </button>
          </div>
        </div>
      </div>

      <main class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex xl:overflow-hidden">
          <!-- Primary column -->
          <section aria-labelledby="primary-heading"
                   class="min-w-0 flex-1 h-full flex-col overflow-auto lg:order-last">
            <h1 id="primary-heading" class="sr-only">Account</h1>
            <div class="h-full flex flex-col overflow-auto">
              <slot/>
            </div>
          </section>

          <!-- Secondary column (hidden on smaller screens) -->
          <aside
              class="hidden lg:block lg:flex-shrink-0 lg:order-first h-screen overflow-auto border-r border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-900 dark:bg-black">
            <div id="secondary-column" class="h-full relative flex flex-col" :class="secondaryMenuClass"/>
          </aside>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {
	AdjustmentsVerticalIcon,
	IdentificationIcon,
	Bars3Icon,
	HomeIcon,
	UserCircleIcon,
	ArrowLeftOnRectangleIcon,
	XMarkIcon
} from "@heroicons/vue/24/outline";

import {User} from "src/models/User";
import Collapsible from "../components/global/ui/Collapsible";
import handleErrors from "src/mixins/handle_errors";
import LogoImg from "../components/global/ui/Logo";
import UserAvatar from "@/components/front/ui/Avatar";
import NavLink from "@/components/global/NavLink.vue";

export default {
	components: {
		NavLink,
		UserAvatar,
		LogoImg,
		Bars3Icon,
		AdjustmentsVerticalIcon,
		Collapsible,
		Dialog,
		DialogOverlay,
		TransitionChild,
		TransitionRoot,
		IdentificationIcon,
		HomeIcon,
		UserCircleIcon,
		ArrowLeftOnRectangleIcon,
		XMarkIcon,
	},
	name: "FrontLayout",
	mixins: [handleErrors],
	props: {
		user: User,
		isAuthPage: Boolean
	},
	data: () => ({
		coursesCount: 0,
		profileNavigation: [],
		mobileMenuOpen: false,
		showProfileNavigation: false,
	}),
	computed: {
		navigation() {
			let nav = [
				{name: "Home", route: "front.home", icon: "home"},
				{name: "Library", route: "front.library.index", icon: "local_library", badge: this.coursesCount},
			];

			if (this.user.hasCommunity) {
				nav.push({name: "Community", route: "front.community.index", params: {page: "recent"}, icon: "forum"});
			}

			if (this.user.hasLeaderboard) {
				nav.push({name: "Leaderboard", route: "front.leaderboard.index", icon: "emoji_events"});
			}

			nav.push({name: "Profile", route: "front.students.show", params: {page: "profile"}, icon: "admin_panel_settings"});
			return nav;
		},
		menuStyle() {
			return `margin-left: ${this.$router.hasMenuVisible() ? 0: -105}px;`;
		},
		secondaryMenuClass() {
			return this.$router.hasSecondaryMenuVisible() ? "w-64" : "w-0";
		}
	},
	methods: {
		countCourses() {
			return this.$remote.request("students.courses.count")
				.then(({data}) => data);
		},
		loadData() {
			if (this.user.isGuest) {
				return;
			}
			Promise.all([
				this.countCourses(),
			])
				.then(([courses]) => {
					this.coursesCount = courses.count;
				})
				.catch(error => this.handleErrors(error));
		},
		toggleMenu(visibility) {
			this.menuVisible = visibility;
		},
		logout() {
			this.$root.logout(true);
		}
	},
	mounted() {
		this.loadData();
	},
	created() {
		this.profileNavigation = [
			{
				name: this.$t("Settings"),
				route: "front.settings.index",
				params: {page: "profile"},
				icon: AdjustmentsVerticalIcon
			},
			{name: this.$t("Log out"), handler: this.logout, icon: ArrowLeftOnRectangleIcon},
		];
	}
};
</script>

<style lang="scss" scoped>
aside {
  #secondary-column {
    transition: width .4s ease-in-out;
    will-change: width;

    &:empty {
      transition: none;
    }
  }
}

#narrow-menu {
  .user {
    .avatar-wrapper {
      position: relative;
      overflow: hidden;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
      }

      &::before {
        background: rgba(255, 255, 255, 0);
        backdrop-filter: blur(0);
        transition: background .4s, backdrop-filter .4s;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(50px);
        transition: transform .4s;
      }

      &.avatar-wrapper--open {
        &::after {
          transform: translateY(-50px);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
        }
      }
    }

    &:hover {
      .avatar-wrapper {
        &::before {
          background: rgba(255, 255, 255, .5);
          backdrop-filter: blur(2px);
        }

        &::after {
          transform: translateY(0);
        }
      }
    }
  }
}

</style>