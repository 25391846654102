<template>
    <div class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
      <div class="w-0 flex-1 p-4">
        <div class="flex items-center">
          <div class="flex-shrink-0 pt-0.5 -mt-8 -mb-8 -ml-8">
            <img class="h-16 w-16 rounded-full shadow" :src="notification.mediaUrl" :alt="notification.title" />
          </div>
          <div class="ml-3 w-0 flex-1">
            <p v-if="notification.title" class="text-sm font-medium text-gray-900" v-text="notification.title" />
            <p v-if="notification.message" class="mt-1 text-sm text-gray-500" v-text="notification.message" />
          </div>
        </div>
      </div>
      <div class="flex border-l border-gray-200">
        <button
            v-for="action in notification.actions"
            :key="action.label" class="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            @click="actionClicked(action)" v-text="action.label" />
      </div>
    </div>
</template>

<script>
import notification from "src/mixins/notification";
export default {
	name: "MediaNotification",
	mixins: [notification],
	methods: {
		actionClicked(action) {
			this.remove();
			this.$router.push({ name: action.name, params: action.params });
		}
	}
};
</script>