<template>
  <img v-if="user.avatar" class="h-full aspect-square rounded-full" :src="`/img/avatars/${user.avatar}`" :alt="user.fullName" />
  <div v-else :class="[fontSize]" class="aspect-square h-full rounded-full bg-blue-600 dark:bg-indigo-800 text-white flex items-center justify-center font-medium select-none"> {{ initials }}</div>
</template>

<script>
export default {
	name: "UserAvatar",
	props: {
		user: Object,
		fontSize: {
			type: String,
			default: "text-xl"
		}
	},
	computed: {
		initials() {
			const [first_name, last_name] = this.user.name ? this.user.name.split(" ") : [this.user.first_name, this.user.last_name];
			if (first_name && last_name) {
				return (first_name[0]+last_name[0]).toUpperCase();
			}
			if (first_name) {
				return first_name.slice(0, 2).toUpperCase();
			}
			if (last_name) {
				return last_name.slice(0, 2).toUpperCase();
			}
			return "";
		}
	}
};
</script>