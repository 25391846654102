import dayjs from "dayjs";
import {User} from "../models/User";
import {reactive} from "vue";

const load = () => {
	const data = localStorage.getItem("auth");
	if (!data) {
		return emptyData();
	}
	return JSON.parse(data);
};

const emptyData = () => ({
	user: User.guest()
});

export default class Auth {
	constructor() {
		this.data = load();
	}

	static get instance() {
		if (!Auth._instance) {
			Auth._instance = reactive(new Auth());
		}
		return Auth._instance;
	}

	get accessToken() {
		return this.data.access_token || "";
	}

	get issuedAt() {
		return this.data.issued_at || "";
	}

	get expiresIn() {
		return this.data.expires_in || "";
	}

	get type() {
		return this.data.token_type || "";
	}

	get userData() {
		return this.data.user || {};
	}

	get isExpired() {
		if (!this.issuedAt) {
			return true;
		}
		return dayjs.unix(this.issuedAt).add(this.expiresIn, "seconds").isBefore(dayjs());
	}

	get user() {
		return new User(this.userData);
	}

	get isLoggedIn() {
		return !this.isExpired;
	}

	canVisitRoute(route) {
		if (typeof route.meta.roles === "undefined") {
			return true;
		}

		if (this.isExpired) {
			this.logout();
		}

		return route.meta.roles.includes(this.user.role);
	}

	updateUser(userData) {
		for (const key in userData) {
			if (Object.hasOwn(userData, key)) {
				this.data.user[key] = userData[key];
			}
		}
		localStorage.setItem("auth", JSON.stringify(this.data));
	}

	login(data) {
		localStorage.setItem("auth", JSON.stringify(data));
		this.data = Object.assign({}, this.data, data);
	}

	logout() {
		localStorage.removeItem("auth");
		this.data = emptyData();
	}
}