import Auth from "../modules/auth";
import ConfirmCanceled from "@/errors/ConfirmCanceled";

export default {
	data: () => ({
		errors: {}
	}),
	methods: {
		handleErrors(error) {
			if (!error) { return; }
			if (error instanceof ConfirmCanceled) {
				return;
			}
			this.$root.errorNotification(error.message);
			this.errors = error.errors || {};
			if (error.status === 401) {
				if (this.$router.isAdminRoute()) {
					this.$router.push({name: "admin.login", params: {"redirectTo": this.$route.fullPath}});
				} else {
					this.$router.push({name: "login", params: {"redirectTo": this.$route.fullPath}});
				}
				Auth.instance.logout();
			}
		},
	}
};