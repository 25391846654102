import {createRouter, createWebHistory, RouterView} from "vue-router";

import {h} from "vue";
import Auth from "../modules/auth";

export function setupRouter() {

	// setup routes
	const routes = [
		{
			name: "admin.home",
			path: "/admin",
			component: () => import("../components/admin/pages/home/Index"),
			meta: {
				roles: ["admin", "teacher"],
				title: "Home"
			}
		},
		{
			name: "admin.courses.index",
			path: "/admin/courses",
			component: () => import("../components/admin/pages/courses/Index"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Courses"
			}
		},
		{
			name: "admin.courses.show",
			path: "/admin/courses/:courseId",
			component: () => import("../components/admin/pages/courses/Show"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Loading course..."
			}
		},
		{
			name: "admin.courses.preview",
			path: "/admin/courses/:courseId/~/:itemType/:itemId/:locale",
			component: () => import("../components/admin/pages/courses/Preview"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Loading course..."
			}
		},
		{
			name: "admin.lessons.content",
			path: "/admin/courses/:courseId/lessons/:lessonId/content/:locale",
			component: () => import("../components/admin/pages/lessons/Content"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Loading content..."
			}
		},
		{
			name: "admin.quizzes.content",
			path: "/admin/courses/:courseId/quizzes/:quizId/content",
			component: () => import("../components/admin/pages/quizzes/Content"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Loading quiz..."
			}
		},
		{
			name: "admin.translate",
			path: "/admin/courses/:courseId/translate/:locale?/:section?/:page?",
			component: () => import("../components/admin/pages/translate/Index"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Loading translations..."
			}
		},
		{
			name: "admin.translate.content",
			path: "/admin/courses/:courseId/translate/:translationJobId/item/:translationJobItemId",
			component: () => import("../components/admin/pages/translate/Content"),
			props: true,
			meta: {
				roles: ["admin", "teacher"]
			}
		},
		{
			name: "admin.students.index",
			path: "/admin/student",
			component: () => import("../components/admin/pages/students/Index"),
			props: true,
			meta: {
				roles: ["admin", "teacher"]
			}
		},
		{
			name: "admin.students.show",
			path: "/admin/student/:studentId?/:page?",
			component: () => import("../components/admin/pages/students/Index"),
			props: true,
			meta: {
				roles: ["admin", "teacher"],
				title: "Students Directory"
			}
		},
		{
			name: "admin.teachers.index",
			path: "/admin/teachers",
			component: () => import("../components/admin/pages/teachers/Index"),
			props: true,
			meta: {
				roles: ["admin"],
				title: "Teacher Accounts"
			}
		},
		{
			name: "admin.login",
			path: "/admin/login",
			component: () => import("../components/admin/pages/auth/Login"),
			props: true,
			meta: {
				title: "Teacher Login"
			}
		},
		{
			name: "admin.forgot",
			path: "/admin/forgot",
			component: () => import("../components/admin/pages/auth/Forgot"),
			props: true,
			meta: {
				title: "Forgot Password"
			}
		},
		{
			name: "admin.recover",
			path: "/admin/recover",
			component: () => import("../components/admin/pages/auth/Recover"),
			props: true,
			meta: {
				title: "Reset Password"
			}
		},
		{
			name: "admin.register",
			path: "/admin/register",
			component: () => import("../components/admin/pages/auth/Register"),
			meta: {
				title: "Teacher Registration"
			}
		},
		{
			name: "front.oops",
			path: "/:lang/oops",
			props: true,
			component: () => import("../components/front/pages/oops/Oops"),
			meta: {
				title: "Learn about phishing, ransomware, strong passwords and other cyber security topics"
			}
		},
		{
			name: "front.home",
			path: "/",
			component: () => import("../components/front/pages/home/Index"),
			meta: {
				roles: ["student"],
				title: "Home"
			}
		},
		{
			name: "front.students.show",
			path: "/student/:page?",
			props: true,
			component: () => import("../components/front/pages/student/Show"),
			meta: {
				roles: ["student"],
				title: "Profile"
			}
		},
		{
			name: "front.settings.index",
			path: "/settings/:page",
			props: true,
			component: () => import("../components/front/pages/settings/Index"),
			meta: {
				roles: ["student"],
				title: "Settings"
			}
		},
		{
			name: "front.library",
			path: "/library",
			// we need this to render the children (see note below)
			component: {render: () => h(RouterView)},
			meta: {
				roles: ["student"],
				title: "Library",
			},
			children: [
				{
					name: "front.library.index",
					path: "courses",
					component: () => import("../components/front/pages/library/Index"),
					meta: {
						roles: ["student"]
					}
				},
				{
					name: "front.library.courses.show",
					path: ":courseSlug~:courseId",
					component: () => import("../components/front/pages/courses/Show"),
					props: true,
					meta: {
						roles: ["student"],
						title: "Loading...",
					}
				},
				{
					name: "front.library.courses.finish",
					path: ":courseSlug~:courseId/finish",
					component: () => import("../components/front/pages/courses/Item"),
					props: route => ({...route.params, isFinished: true}),
					meta: {
						roles: ["student"],
						title: "Loading...",
					}
				},
				{
					name: "front.library.courses.item",
					path: ":courseSlug~:courseId/item/:itemSlug~:itemId",
					component: () => import("../components/front/pages/courses/Item"),
					props: route => ({...route.params, isFinished: false}),
					meta: {
						roles: ["student"],
						title: "Loading...",
					}
				},
			]
		},
		{
			name: "front.leaderboard.index",
			path: "/leaderboard",
			component: () => import("../components/front/pages/leaderboard/Index"),
			meta: {
				roles: ["student"],
				title: "Leaderboard"
			}
		},
		{
			name: "front.community.index",
			path: "/community/:page",
			props: true,
			component: () => import("../components/front/pages/community/Index"),
			meta: {
				roles: ["student"],
				title: "Community"
			}
		},
		{
			name: "front.community.show",
			path: "/community/posts/:postId",
			props: true,
			component: () => import("../components/front/pages/community/Show"),
			meta: {
				roles: ["student"],
				title: "Community",
			}
		},
		{
			name: "login",
			path: "/login",
			component: () => import("../components/front/pages/auth/Login"),
			props: true,
			meta: {
				title: "Student Login"
			}
		},
		{
			name: "login.check",
			path: "/login/check",
			component: () => import("../components/front/pages/auth/Check"),
			props: true,
			meta: {
				title: "Login"
			}
		},
		{
			name: "magic",
			path: "/magic/:token",
			component: () => import("../components/front/pages/auth/Magic"),
			props: true
		}
	];

	// create router instance
	const router = createRouter({
		history: createWebHistory(),
		routes
	});

	const redirectToLogin = (to, next) => next({
		name: "login",
		params: {"redirectTo": to.fullPath}
	});

	const checkHiddenRoutes = (to, next) => {
		const user = Auth.instance.user;

		if ((to.name?.includes("leaderboard") && !user.hasLeaderboard) ||
			(to.name?.includes("community") && !user.hasCommunity)){
			return next({
				"name": "front.home"
			});
		}

		next();
	};

	// navigation guards
	router.beforeEach(async (to, from, next) => {
		Auth.instance.canVisitRoute(to) ? checkHiddenRoutes(to, next) : redirectToLogin(to, next);
	});

	router.hasMenuVisible = function () {
		return this.currentRoute.value.name !== "front.library.courses.item";
	};

	router.hasSecondaryMenuVisible = function () {
		return this.currentRoute.value.name === "front.library.courses.item";
	};

	router.isAuthRoute = function () {
		return ["login", "login.check", "magic", "admin.login", "admin.register"].includes(this.currentRoute.value.name);
	};

	router.isAdminRoute = function () {
		return this.currentRoute.value.meta.roles?.includes("admin");
	};

	router.isStudentRoute = function () {
		return this.currentRoute.value.meta.roles?.includes("student");
	};

	router.isGuestRoute = function () {
		return !Object.hasOwn(this.currentRoute.value.meta, "roles");
	};

	router.refresh = function () {
		this.push(this.currentRoute);
	};

	return router;
}