import dayjs from "dayjs";
// eslint-disable-next-line no-undef
require("dayjs/locale/ro");
// eslint-disable-next-line no-undef
require("dayjs/locale/el");
// eslint-disable-next-line no-undef
require("dayjs/locale/es");

import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import pusher_js from "pusher-js";
import duration from "dayjs/plugin/duration";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

window.dayjs = dayjs;

if (!Array.prototype.first) {
	Array.prototype.first = function () {
		return this[0];
	};
}

if (!Array.prototype.last) {
	Array.prototype.last = function () {
		return this[this.length - 1];
	};
}

if (!String.prototype.capitalize) {
	Object.defineProperty(String.prototype, "capitalize", {
		value: function () {
			return this ? this.charAt(0).toUpperCase() + this.slice(1) : "";
		}
	});
}

window.Pusher = pusher_js;
// window.Pusher.logToConsole = true;


// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
	document.documentElement.classList.add("dark");
} else {
	document.documentElement.classList.remove("dark");
}
//
// // Whenever the user explicitly chooses light mode
// localStorage.theme = "light";
//
// // Whenever the user explicitly chooses dark mode
// localStorage.theme = "dark";
//
// // Whenever the user explicitly chooses to respect the OS preference
// localStorage.removeItem("theme");