<template>
  <router-link :to="{name: item.route, params: item.params}"
               v-slot="{ href, isActive, navigate }" custom>
    <a @click="navigate" :href="href"
       :class="[isActive || isSubActive ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group relative w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']"
       :aria-current="isActive || isSubActive ? 'page' : undefined">
      <slot />
    </a>
  </router-link>
</template>

<script>

export default {
	name: "NavLink",

	props: {
		item: Object,
	},

	computed: {
		isSubActive() {
			// this.$route.name includes item.route
			return this.$route.name.includes(this.item.route);
		},
	},
};
</script>