export class NotificationObj {
	constructor({id, title, message, mediaUrl, actions, type}) {
		this.id = id;
		this.title = title;
		this.message = message;
		this.mediaUrl = mediaUrl;
		this.actions = actions || [];
		this.type = type;
	}

	get isMedia() {
		return this.type === "media";
	}

	get component() {
		return this.type === "media" ? "MediaNotification" : "Notification";
	}

	get color() {
		switch (this.type) {
		case "success":
			return "green-400";
		case "error":
			return "red-400";
		case "warning":
			return "yellow-400";
		case "info":
			return "blue-400";
		default:
			return "indigo-400";
		}
	}

	get icon() {
		switch (this.type) {
		case "success":
			return "CheckCircleIcon";
		case "error":
			return "ExclamationCircleIcon";
		case "warning":
			return "ExclamationTriangleIcon";
		case "info":
			return "InformationCircleIcon";
		default:
			return "";
		}
	}
}