export class Locale {
	constructor({key, label}) {
		this.key = key;
		this.label = label;
		this.flag = Locale.findFlag(key);
		this.progress = 0;
	}

	static findFlag(locale) {
		let [language, country] = locale.toLowerCase().split("_");
		if (language === "ca") {
			country = "es-ct";
		}
		if (country === "la") {
			country = "latam";
		}
		return `/img/flags/${country}.svg`;
	}

	static find(code) {
		return new Locale(window.global.locales.find(locale => locale.key === code));
	}

	get labelProgress() {
		return `${this.label} (${this.progress}%)`;
	}

	setProgress(progress) {
		this.progress = progress;
		return this;
	}
}
