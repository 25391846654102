export default class Whitelabel {
	constructor(remote) {
		this.remote = remote;
		this.data = {};
	}

	init() {
		return this.remote.query("whitelabel", {}, location.hostname)
			.then(({data}) => this.data = data);
	}

	get _companyId() {
		return this.data.company_id;
	}

	get appName() {
		return this.data.name;
	}

	get manager() {
		return this.data.manager;
	}

	get domain() {
		return this.data.domain;
	}

	get supportEmail() {
		return this.data.support_email;
	}

	logo(darkMode = false) {
		const type = darkMode ? "logo_dark" : "logo_light";
		return `https://media.attacksimulator.com/files/${this._companyId}/${type}`;
	}

	get favicon() {
		return `https://media.attacksimulator.com/files/${this._companyId}/favicon_edu`;
	}

	get signature() {
		return `https://media.attacksimulator.com/files/${this._companyId}/signature`;
	}

	setFavicon() {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = this.favicon;
	}
}