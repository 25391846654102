<template>
  <div class="h-full flex bg-gray-50 dark:bg-black">
    <template v-if="!isAuthPage">
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="fixed inset-0 flex z-100 lg:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white dark:bg-black focus:outline-none">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">{{ $t("Close sidebar") }}</span>
                    <XMarkIcon class="h-6 w-6 text-white dark:text-black" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="flex-shrink-0 flex items-center px-4">
                  <logo-img class="h-8 w-auto dark:hidden" />
                  <logo-img dark class="h-8 w-auto hidden dark:block" />
                </div>
                <nav aria-label="Sidebar" class="mt-5">
                  <div class="px-2 space-y-1">
                    <router-link v-for="item in navigation" :key="item.name" :to="{name: item.to}" v-slot="{ href, navigate, isActive }" custom>
                      <a @click="navigate" :href="href" :class="[isActive ? 'bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100' : 'text-gray-600  dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-100', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']"
                         :aria-current="isActive ? 'page' : undefined">
                        <component :is="item.icon" :class="[isActive ? 'text-gray-500 dark:text-gray-300' : 'text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300', 'mr-4 h-6 w-6']" aria-hidden="true" />
                        {{ item.name }}
                      </a>
                    </router-link>
                  </div>
                  <hr class="border-t border-gray-200 dark:border-gray-800 my-5" aria-hidden="true" />
                  <div class="px-2 space-y-1">
                    <a v-for="item in secondaryNavigation" :key="item.name" :href="item.href" class="text-gray-600  dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-100 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                      <component :is="item.icon" class="text-gray-400 dark:text-gray-600 group-hover:text-gray-500 dark:group-hover:text-gray-300 mr-4 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                      {{ item.name }}
                    </a>
                  </div>
                </nav>
              </div>
              <div class="flex-shrink-0 flex border-t border-gray-200 dark:border-gray-800 p-4">
                <a href="#" class="flex-shrink-0 group block">
                  <div class="flex items-center">
                    <div>
                      <user-avatar :user="user" class="inline-block h-9 w-9 rounded-full text-white" font-size="12"/>
                    </div>
                    <div class="ml-3">
                      <p class="text-base font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:hover:text-gray-100">
                        {{ user.name }}
                      </p>
                      <p class="text-sm font-medium text-gray-500 dark:text-gray-300 group-hover:text-gray-700 dark:group-hover:text-gray-300">
                        {{ $t("View profile") }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-900 dark:bg-gray-900 dark:border-gray-800">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center justify-between flex-shrink-0 px-4">
              <logo-img class="h-8 w-auto dark:hidden" />
              <logo-img dark class="h-8 w-auto hidden dark:block" />
              <p class="text-gray-900 dark:text-gray-50">{{ user.role }}</p>
            </div>
            <nav class="mt-5 flex-1" aria-label="Sidebar">
              <div class="px-2 space-y-1">
                <router-link  v-for="item in navigation" :key="item.name" :to="{name: item.to}" v-slot="{ href, navigate, isActive }" custom>
                  <a @click="navigate" :href="href" :class="[isActive ? 'bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100' : 'text-gray-600  dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-100', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']" :aria-current="isActive ? 'page' : undefined">
                    <component :is="item.icon" :class="[isActive ? 'text-gray-500 dark:text-gray-300' : 'text-gray-400 dark:text-gray-600 group-hover:text-gray-500 dark:group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ $t(item.name) }}
                  </a>
                </router-link>
              </div>
              <hr class="border-t border-gray-200 dark:border-gray-800 my-5" aria-hidden="true" />
              <div class="flex-1 px-2 space-y-1">
                <a v-for="item in secondaryNavigation" :key="item.name" :href="item.href" class="text-gray-600  dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-100 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <component :is="item.icon" class="text-gray-400 dark:text-gray-600 group-hover:text-gray-500 dark:group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  {{ item.name }}
                </a>
              </div>
            </nav>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-200 dark:border-gray-800 p-4">
            <div class="flex-shrink-0 w-full group block">
              <div class="flex items-center gap-3">
                <user-avatar :user="user" class="inline-block h-9 w-9 flex-shrink-0 rounded-full text-white" font-size="12"/>
                <div class="">
                  <p class="text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-100">
                    {{ user.full_name }}
                  </p>
                  <p class="text-sm font-bold text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-100">
                    {{ user.company_name }}
                  </p>

                </div>
                <div class="flex-1"></div>
                <a @click.prevent="logout" :title="$t('Logout')" href="#" class="text-xs font-medium text-gray-500 dark:text-gray-300 group-hover:text-gray-700 dark:group-hover:text-gray-300">
                  <ArrowRightStartOnRectangleIcon class="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
      <div class="lg:hidden">
        <div class="flex items-center justify-between bg-gray-50 dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 px-4 py-1.5">
          <div>
            <logo-img class="h-8 w-auto dark:hidden" />
            <logo-img dark class="h-8 w-auto hidden dark:block" />
          </div>
          <div>
            <button type="button" class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600" @click="sidebarOpen = true">
              <span class="sr-only">{{ $t("Open sidebar") }}</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex-1 relative z-0 flex flex-col overflow-auto">
        <slot />
      </div>
    </div>

    <div class="relative z-200">
      <notifications ref="notification"/>
      <confirm ref="confirm"/>
    </div>
  </div>
</template>

<script>
import Confirm from "src/components/global/ui/Confirm";
import Notifications from "src/components/global/ui/Notifications";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";

import {
	CalendarIcon,
	HomeIcon,
	Bars3Icon,
	UserGroupIcon,
	AcademicCapIcon,
	ArrowRightStartOnRectangleIcon,
	XMarkIcon,
} from "@heroicons/vue/24/outline";
import { ChevronLeftIcon, FunnelIcon, EnvelopeIcon, PhoneIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import Auth from "src/modules/auth";
import LogoImg from "../components/global/ui/Logo";
import UserAvatar from "../components/front/ui/Avatar";

const secondaryNavigation = [
	// { name: "Apps", href: "#", icon: SquaresPlusIcon },
	// { name: "Settings", href: "#", icon: CogIcon },
];

export default {
	name: "AdminLayout",
	components: {
		UserAvatar,
		LogoImg,
		Confirm,
		Notifications,
		Dialog,
		DialogOverlay,
		TransitionChild,
		TransitionRoot,
		ChevronLeftIcon,
		AcademicCapIcon,
		FunnelIcon,
		EnvelopeIcon,
		Bars3Icon,
		PhoneIcon,
		MagnifyingGlassIcon,
		XMarkIcon,
		ArrowRightStartOnRectangleIcon,
	},
	data: () => ({
		secondaryNavigation,
		sidebarOpen: false,
	}),
	props: {
		isAuthPage: Boolean
	},
	computed: {
		user(){
			return Auth.instance.user;
		},
		navigation() {
			let menu = [
				{ name: "Dashboard", to: "admin.home", icon: HomeIcon },
				{ name: "Courses", to: "admin.courses.index", icon: CalendarIcon },
				{ name: "Directory", to: "admin.students.show", icon: UserGroupIcon },
			];
			if (this.user.isAdmin) {
				menu.push(
					{ name: "Teachers", to: "admin.teachers.index", icon: AcademicCapIcon },
				);
			}

			return menu;
		}
	},
	methods: {
		wideScreen() {
			this.isWide = true;
		},
		normalScreen() {
			this.isWide = false;
		},
		logout() {
			this.$root.logout();
		}
	},
	mounted() {
		this.$emitter.on("wide-screen", this.wideScreen);
		this.$emitter.on("normal-screen", this.normalScreen);
		this.$language.course = "en_US";
	},
	beforeUnmount() {
		this.$emitter.off("wide-screen", this.wideScreen);
		this.$emitter.off("normal-screen", this.normalScreen);
	}
};
</script>