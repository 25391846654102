import {nextTick} from "vue";
import {createI18n} from "vue-i18n";

async function loadLocale(locale) {
	return await import(
		/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`);
}

export async function setupI18n(locale) {
	const localeData = await loadLocale(locale);
	let messages = {};
	messages[locale] = localeData.default;

	return createI18n({
		globalInjection: true,
		sync: true,
		legacy: true,
		locale: locale,
		fallbackLocale: locale,
		messages
	});
}

export function setI18nLanguage(i18n, locale) {
	if (i18n.mode === "legacy") {
		i18n.global.locale = locale;
	} else {
		i18n.global.locale.value = locale;
	}
	/**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
	document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(i18n, locale) {
	// load locale messages with dynamic import
	const messages = await loadLocale(locale);

	// eslint-disable-next-line no-undef
	i18n.global.setLocaleMessage(locale, messages.default);
	return nextTick();
}